import React, {useEffect} from 'react';
import useBooking, {BookingEvent, BookingFlowState} from './hook/useBooking';
import {withBooking} from './hook/withBooking';
import TicketDetails from './TicketDetails';
import BookingSuccess from './BookingSuccess';
import BookingConfirmation from './BookingConfirmation';

const BookingFlow = () => {
  const {state, addEvent} = useBooking();
  useEffect(() => {
    if (state.flowState === BookingFlowState.INIT) {
      addEvent({type: BookingEvent.INIT});
    }
  }, [state.flowState, addEvent]);
  switch (state.flowState) {
    case BookingEvent.INIT:
    case BookingFlowState.TICKET_DETAILS:
      return <TicketDetails/>;
    case BookingFlowState.BOOKING_CONFIRMATION:
      return <BookingConfirmation />
    case BookingFlowState.BOOKING_SUCCESSFUL:
      return <BookingSuccess />;
    default:
      throw new Error('Invalid flow state.');
  }
};

export default withBooking(BookingFlow);
