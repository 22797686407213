// The stable createMuiTheme does not support ReactStrictMode yet and it's rolling out the support in the next version
// which is in unstable_createMuiStrictModeTheme.
// Once we upgraded @material-ui/core to the next version we can pull in the stable createMuiTheme.
// I have done regression testing on the theme and found that there is no issues using this "unstable" version
// It helps remove the noisy warnings from React linter and helps us more focused on the real issues.
// https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
import {responsiveFontSizes, unstable_createMuiStrictModeTheme as createMuiTheme} from '@material-ui/core';
import STIXTwoMath from '../assets/fonts/STIXTwoMath-Regular.ttf';

// breakpoints
const values = {
  xs: 0,
  sm: 500,
  md: 760,
  lg: 1080,
  xl: 1520,
};

const colors = {
  // white
  xxLightWhite: 'rgba(255, 255, 255, 0.1)',
  xLightWhite: 'rgba(255, 255, 255, 0.8)',
  lightWhite: 'rgba(255, 255, 255, 0.9)',
  white: 'rgba(255, 255, 255, 1)',
  
  // red
  red: 'rgba(244, 67, 54, 1)',
  
  // orange
  orange: 'rgba(255, 152, 0, 1)',
  darkOrange: 'rgba(255, 112, 0, 1)',
  
  // green
  green: 'rgba(76, 175, 80, 1)',
  
  // blue
  xxLightBlue: 'rgb(84,177,248, 0.1)',
  xLightBlue: 'rgb(84,177,248, 0.2)',
  lightBlue: 'rgb(84,177,248)',
  blue: 'rgba(2, 181, 222, 1)',
  darkBlue: 'rgba(1, 87, 155, 1)',
  xDarkBlue: 'rgba(20, 0, 100, 1)',
  xxDarkBlue: 'rgb(12, 59, 85, 0.8)',
  xxxDarkBlue: 'rgba(5, 30, 52, 1)',
  xxxxDarkBlue: 'rgb(2,18,33)',
  
  // purple
  lightPurple: 'rgb(129,5,196, 0.1)',
  purple: 'rgb(129,5,196)',
  xPurple: 'rgba(140, 0, 240, 1)',
  
  // grey
  xlightGrey: 'rgb(250, 250, 250)',
  lightGrey: 'rgba(174, 174, 174, 0.5)',
  grey: 'rgba(124, 124, 124, 0.4)',
  darkGrey: 'rgba(79, 79, 79, 0.7)',
  xDarkGrey: 'rgb(71,71,71)',
  matteGrey: 'rgba(125, 125, 125, 1)',
  solidLightGrey: 'rgb(189,189,189)',
  
  // black
  lightBlack: 'rgba(0, 0, 0, 0.8)',
  black: 'rgba(0, 0, 0, 1)',
  
  // soft colours
  softGrey: 'rgba(50, 50, 93, 0.1)',
  softBlack: 'rgba(0, 0, 0, 0.2)',
  softWhite: 'rgba(255, 255, 255, 0.5)',
  
  panelGrey: 'rgba(233, 233, 233, 0.8)',
};

// fonts
const dancing = {
  fontFamily: 'STIXTwoMath',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${STIXTwoMath}) format('truetype')`,
};

const dancingBold = {
  fontFamily: 'STIXTwoMath',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${STIXTwoMath}) format('truetype')`,
};

// mainTheme
let mainTheme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  typography: {
    fontFamily: ['STIXTwoMath'],
    button: {
      fontWeight: 'bold',
    },
    h1: {
      fontSize: '2.4rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2.2rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '2.1rem',
      fontWeight: 500,
    },
    body1: {
      color: colors.white,
      fontSize: '1.1rem',
    },
    body2: {
      color: colors.white,
      fontSize: '1.1rem',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        color: colors.white,
      },
    },
    MuiSnackbar: {
      root: {
        left: 16,
        right: 16,
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [dancing, dancingBold],
      },
    },
    MuiToolbar: {
      gutters: {
        '@media (min-width: 600px)': {
          paddingLeft: 16,
        },
      },
      regular: {
        '@media (min-width: 600px)': {
          minHeight: 42,
        },
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(even)': {
          backgroundColor: colors.xxLightBlue,
        },
        '&$hover:hover': {
          backgroundColor: colors.xLightBlue,
        },
      },
    },
    MuiTableCell: {
      root: {
        color: colors.grey,
        padding: '8px 16px 8px 16px',
        borderBottom: 'none',
      },
      head: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: colors.panelGrey,
        color: colors.xDarkBlue,
      },
      body: {
        color: colors.xDarkBlue,
      },
    },
    MuiTableSortLabel: {
      root: {
        color: colors.white,
        '&:focus': {
          color: colors.white,
        },
        '&:hover': {
          fontWeight: 'bold',
          color: colors.white,
          '& $icon': {
            fontWeight: 'bold',
            color: colors.white,
            opacity: 1,
          },
        },
        '&$active': {
          color: colors.white,
          fontWeight: 'bold',
          '&:hover': {
            color: colors.white,
          },
        },
        // This is horrible but it's necessary to fix the damn bug in material table sort label
        // It's used to defeat this selector
        // .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon
        '&$active$root$active$root $icon': {
          color: colors.white,
          fontWeight: 'bold',
        },
      },
    },
    MuiTabs: {
      root: {
        borderBottom: `1px solid`,
        borderBottomColor: colors.lightGrey,
        marginBottom: 16,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTabPanel: {
      root: {
        padding: 0,
      },
    },
    MuiLink: {
      root: {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
      underlineHover: {
        fontWeight: 'bold',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 600,
        color: colors.white,
      },
    },
    MuiInput: {
      root: {
        color: colors.white,
      },
      underline: {
        '&::before': {
          borderBottomColor: colors.white,
        },
      },
    },
    MuiTablePagination: {
      root: {
        color: colors.xDarkBlue,
        display: 'flex',
        justifyContent: 'flex-end',
      },
      caption: {
        fontSize: '0.75rem',
      },
    },
    MuiSelect: {
      root: {
        color: colors.white,
      },
      icon: {
        color: colors.white,
      },
      select: {
        '&$disabled': {
          color: colors.darkGrey,
        },
      },
    },
    MuiTypography: {
      root: {
        color: colors.white,
      },
    },
    MuiContainer: {
      root: {
        '@media (min-width: 600px)': {
          paddingLeft: 16,
          paddingRight: 16,
        },
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiDialogTitle: {
      root: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    MuiDialogContent: {
      root: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    MuiInputBase: {
      input: {
        '&$disabled': {
          color: colors.darkGrey,
        },
      },
    },
    Mui: {
      '&$disabled': {
        color: colors.darkGrey,
      },
    },
    MuiAlert: {
      filledWarning: {
        backgroundColor: colors.darkOrange,
      },
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    up: (key) => `@media (min-width:${values[key]}px)`,
  },
  spacing: 8,
  palette: {
    action: {
      disabledBackground: colors.grey,
      disabled: colors.white
    },
    background: {
      default: colors.lightBlack,
    },
    disabledButton: {
      main: colors.grey,
    },
    primary: {
      light: colors.lightWhite,
      main: colors.white,
      dark: colors.white,
    },
    secondary: {
      light: colors.xLightWhite,
      main: colors.xxLightWhite,
      dark: colors.xxLightWhite,
    },
    smartPasswordHint: {
      satisfied: colors.green,
      notSatisfied: colors.darkGrey,
    },
    drawerItem: {
      selectedBackgroundColor: colors.xxxDarkBlue,
      divider: colors.xxLightWhite,
      menuHeading: colors.solidLightGrey,
    },
    chargePointStatus: {
      available: colors.blue,
      unavailable: colors.darkGrey,
      charging: colors.purple,
    },
    connectorStatus: {
      available: colors.blue,
      unavailable: colors.darkGrey,
      charging: colors.purple,
    },
    sessionStatus: {
      active: colors.green,
      completed: colors.darkGrey,
      other: colors.red,
    },
    chargeTagStatus: {
      active: colors.green,
      deactivated: colors.red,
    },
    notification: {
      info: colors.blue,
      success: colors.green,
      warning: colors.darkOrange,
      error: colors.red,
    },
    processingBar: {
      backgroundColor: colors.xPurple,
    },
    tab: {
      divider: colors.darkGrey,
    },
    divider: colors.lightGrey,
    chart: {
      bar: colors.green,
    },
  },
  leftDrawer: {
    width: 280,
  },
});
mainTheme = responsiveFontSizes(mainTheme);

export default mainTheme;
