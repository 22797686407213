import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import useBooking, {BookingEvent} from './hook/useBooking';
import FullPageContainer from '../FullPageContainer/FullPageContainer';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Box, Button, MenuItem, Select, Typography} from '@material-ui/core';
import {TextField} from 'formik-material-ui';

const useStyles = makeStyles((theme) => ({
  formRow: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  button: {
    ":disabled": {
      backgroundColor: 'red',
    }
  }
}));

const TicketDetails = () => {
  const classes = useStyles(mainTheme);
  const {state, addEvent} = useBooking();
  const handleChange = (event) => {
    addEvent({type: BookingEvent.TICKET_TYPE_CHANGED, payload: {type: event.target.value}});
  };
  return (
    <>
      <FullPageContainer headingLabel='Đăng Ký Mua Vé'>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            mobileNumber: '',
            numberOfTicket: '',
          }}
          validationSchema={Yup.object({
            firstName: Yup.string()
              .required('Xin điền thông tin')
              .matches(/[a-zA-Z]/, 'Tên không hợp lệ'),
            email: Yup.string()
              .required('Xin điền thông tin')
              .matches(/[a-zA-Z]/, 'Email không hợp lệ'),
            mobileNumber: Yup.string()
              .required('Xin điền thông tin')
              .matches(/^(\+\d{1,3}[- ]?)?\d{9}/, 'Số điện thoại không phù hợp'),
            numberOfTicket: Yup.number()
              .min(1)
              .max(state.remainingTickets)
              .required('Xin điền thông tin')
              .typeError('Xin điền thông tin'),
          })}
          onSubmit={(values) => {
            const ticketDetails = {
              ...values,
            };
            
            addEvent({type: BookingEvent.TICKET_DETAILS_RECEIVED, payload: {ticketDetails}});
          }}
        >
          {(formikProps) => (
            <>
              <Form>
                <Box classes={{root: classes.formRow}}>
                  <Field name='firstName' component={TextField} type='text' variant='standard' label='Tên' fullWidth autoFocus />
                </Box>
                <Box classes={{root: classes.formRow}}>
                  <Field name='email' component={TextField} type='text' variant='standard' label='Email' fullWidth />
                </Box>
                <Box classes={{root: classes.formRow}}>
                  <Field name='mobileNumber' component={TextField} type='text' variant='standard' label='Số Điện Thoại' fullWidth />
                </Box>
                {/*<Box classes={{root: classes.formRow}}>*/}
                {/*  <Typography>*/}
                {/*    Số vé còn lại: {state.remainingTickets}*/}
                {/*  </Typography>*/}
                {/*</Box>*/}
                <Box classes={{root: classes.formRow}}>
                  <Field name='numberOfTicket' type='text' component={TextField} variant='standard' label='Số vé muốn mua' fullWidth />
                </Box>
                <Box classes={{root: classes.formRow}}>
                  <Select defaultValue={"normal"} onChange={handleChange} disableUnderline>
                    <MenuItem key={"normal"} value={"normal"} style={{backgroundColor: 'black', color: 'white'}}>
                      Yêu (70$)
                    </MenuItem>
                    <MenuItem key={"student"} value={"student"} style={{backgroundColor: 'black', color: 'white'}}>
                      Thương (60$)
                    </MenuItem>
                  </Select>
                </Box>
                <Box classes={{root: classes.formRow}}>
                  <Button classes={{ disabled: classes.button }} type='submit' variant='contained' color='primary' size='large' fullWidth disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>
                    Mua Vé
                  </Button>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </FullPageContainer>
    </>
  );
};

export default TicketDetails;
