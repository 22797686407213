import React, {createContext, useContext, useReducer} from 'react';
import _ from 'lodash';
import bookingService from '../../../services/bookingService';

// events
export const BookingEvent = {
  INIT: 'INIT',
  TICKET_DETAILS_RECEIVED: 'TICKET_DETAILS_RECEIVED',
  TICKET_DETAILS_CONFIRMED: 'TICKET_DETAILS_CONFIRMED',
  TICKET_TYPE_CHANGED: 'TICKET_TYPE_CHANGED',
  BACK_BUTTON_PRESSED: 'BACK_BUTTON_PRESSED',
};

// flow states
export const BookingFlowState = {
  INIT: 'INIT',
  TICKET_DETAILS: 'TICKET_DETAILS',
  CONFIRM_BOOKING: 'CONFIRM_BOOKING',
  BOOKING_SUCCESSFUL: 'REGISTRATION_SUCCESSFUL',
  BOOKING_CONFIRMATION: 'BOOKING_CONFIRMATION',
};

// initial state
const initialState = {
  loading: false,
  flowState: BookingFlowState.INIT,
  ticketType: 'normal',
  totalCost: 0,
  remainingTickets: 0,
};

// reducer
const reducer = (state, newState) => ({...state, ...newState});

// context
const bookingContext = createContext();

// provider
export const BookingProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <bookingContext.Provider value={{state, dispatch}}>{children}</bookingContext.Provider>
  );
};

// hook
const useBooking = () => {
  const {state, dispatch} = useContext(bookingContext);
  const calculateCost = (numberOfTicket, ticketType) => {
    switch (ticketType) {
      case 'student':
        return numberOfTicket * 60;
      case 'normal':
        return numberOfTicket * 70;
      default:
        throw new Error(`Unhandled ticket type: ${ticketType}`);
    }
  }
  const addEvent = (event) => {
    switch (event.type) {
      case BookingEvent.INIT:
        bookingService.getRemaining().subscribe(
          (result) =>
            dispatch({
              flowState: BookingFlowState.TICKET_DETAILS,
              remainingTickets: result,
            }),
          (error) =>
            dispatch({
              flowState: BookingFlowState.TICKET_DETAILS,
            })
        )
        break;
      case BookingEvent.TICKET_DETAILS_RECEIVED:
        dispatch({
          flowState: BookingFlowState.BOOKING_CONFIRMATION,
          totalCost: calculateCost(event.payload.ticketDetails.numberOfTicket, state.ticketType),
          firstName:event.payload.ticketDetails.firstName,
          email:event.payload.ticketDetails.email,
          mobileNumber:event.payload.ticketDetails.mobileNumber,
          numberOfTicket:event.payload.ticketDetails.numberOfTicket,
          ticketType: state.ticketType,
        });
        break;
      case BookingEvent.TICKET_DETAILS_CONFIRMED:
        dispatch({
          loading: true
        });
        bookingService.createBooking(
          state.firstName,
          state.email,
          state.mobileNumber,
          state.numberOfTicket,
          state.ticketType,
        ).subscribe(
          (result) =>
            dispatch({
              flowState: BookingFlowState.BOOKING_SUCCESSFUL,
              bookingId: result,
            }),
          (_) =>
            dispatch({
              flowState: BookingFlowState.BOOKING_SUCCESSFUL,
            })
        );
        break;
      case BookingEvent.TICKET_TYPE_CHANGED:
        dispatch({
          ticketType: event.payload.type,
        });
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };
  
  return {
    state,
    addEvent,
  };
};

export default useBooking;
