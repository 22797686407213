import React, {useState} from 'react';
import FullPageContainer from '../FullPageContainer/FullPageContainer';
import {Box, Button, Typography} from '@material-ui/core';
import useBooking, {BookingEvent} from './hook/useBooking';


const BookingConfirmation = () => {
  const {state, addEvent} = useBooking();
  const confirmBooking = () => {
    addEvent({type: BookingEvent.TICKET_DETAILS_CONFIRMED});
  }
  let button;
  if (state.loading) {
    button = <Button type='submit' variant='contained' color='primary' size='large' fullWidth onClick={confirmBooking} disabled>Mua Vé</Button>;
  } else {
    button = <Button type='submit' variant='contained' color='primary' size='large' fullWidth onClick={confirmBooking}>Mua Vé</Button>;
  }
  return (
    <FullPageContainer headingLabel='Xác nhận thông tin vé'>
      <Box maxWidth='md'>
        <Typography>
          Tên: {state.firstName}
        </Typography>
        <Typography>
          Email: {state.email}
        </Typography>
        <Typography>
          Số điện thoại: {state.mobileNumber}
        </Typography>
        <br/><br/>
        <Typography>
          Số lượng vé: {state.numberOfTicket}<br/>
          Loại vé: {state.ticketType === 'normal'? 'Yêu' : 'Thương'}<br/>
          Tổng tiền: ${state.totalCost}<br/>
        </Typography>
        <br/><br/>
        {button}
      </Box>
    </FullPageContainer>
  );
};

export default BookingConfirmation;
