import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Routes from '../../routes/Routes';

const App = () => (
  <>
    <CssBaseline />
    <React.StrictMode>
      <main>
        <Routes />
      </main>
    </React.StrictMode>
  </>
);

export default App;
