import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import paths from './paths';
import BookingFlow from '../components/BookingFlow/BookingFlow';

const Routes = () => (
  <Router>
    <Switch>
      <Redirect exact from={paths.HOME} to={paths.BOOKING} />
      <Route exact path={paths.BOOKING} component={BookingFlow} />
      <Route exact path='*' component={BookingFlow} />
    </Switch>
  </Router>
);

export default Routes;
