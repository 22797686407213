import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import mainTheme from '../../themes/mainTheme';
import {Box, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  headingLabel: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  subheadingLabel: {
    marginBottom: theme.spacing(3),
  },
}));

const FullPageContainer = ({headingLabel, subheadingLabel, children}) => {
  const classes = useStyles(mainTheme);

  return (
    <Container maxWidth="md">
      <Box color={'red'}>
      <Typography className={classes.headingLabel} variant="h1">
        {headingLabel}
      </Typography>
      <Typography className={classes.subheadingLabel} variant="h2">
        {subheadingLabel}
      </Typography>
      {children}
      </Box>
    </Container>
  );
};

FullPageContainer.propTypes = {
  headingLabel: PropTypes.string.isRequired,
  subheadingLabel: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default FullPageContainer;
