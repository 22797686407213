import React from 'react';
import FullPageContainer from '../FullPageContainer/FullPageContainer';
import {Box, Typography} from '@material-ui/core';
import useBooking from './hook/useBooking';


const BookingSuccess = () => {
  const {state} = useBooking();
  return (
    <FullPageContainer headingLabel='Đặt Vé Thành Công'>
      <Box maxWidth='md'>
        <Typography>
          Hi bạn,<br/>
          Cảm ơn bạn đã đặt vé cùng À Ơi band.<br/>
          Mã số đặt vé: {state.bookingId}<br/>
        </Typography>
        <Typography>
          Vé sẽ được giữ trong vòng 48h kể từ lúc đặt vé. Sau 48h, kết quả đặt vé sẽ vô hiệu lực nếu BTC không xác nhận được thanh toán từ bạn.
        </Typography>
        <Typography>
          ❄️❄️Các hình thức thanh toán❄️❄️
        </Typography>
        <Typography>
          🌟Thông tin chuyển khoản:<br/>
          AVQ:<br/>
          06-0145-0782867-01<br/>
          Reference : (Mã Booking)<br/>
        </Typography>
        <Typography>
          🌟Thanh toán trực tiếp<br/>
          Địa chỉ :<br/>
          5/6 Cleary Road, Panmure (Ms. Oanh)<br/>
        </Typography>
        <br/><br/>
        <Typography>
          ⚡️Kể từ lúc hoàn tất thanh toán (Chuyển khoản hoặc Thanh toán trực tiếp), hệ thống sẽ xuất vé điện tử trong vòng 12h.
        </Typography>
        <br/><br/>
        <Typography>
          Trong trường hợp quá 12h bạn vẫn chưa nhận được vé điện tử, vui lòng liên hệ bộ phận tư vấn của À Ơi theo các hình thức sau:<br/>
        </Typography>
        <Typography>
          🌱Fanpage : A Ơi band<br/>
          🌱Điện thoại :<br/>
          Ms Khanh +64 22 450 9467<br/>
          Ms Oanh +64 20 481 1987<br/>
        </Typography>
        <br/><br/>
        <Typography>
          CẢM ƠN & HẸN GẶP TẠI ĐÊM NHẠC ACOUSTIC “CHẠM” ĐƯỢC TỔ CHỨC TẠI AUCKLAND VÀO NGÀY 27/04/2024.
        </Typography>
      </Box>
    </FullPageContainer>
  );
};

export default BookingSuccess;
