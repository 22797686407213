import _ from 'lodash';

const isEmptyString = (s) => !s || s.length === 0;

const isBlankString = (s) => !s || /^\s*$/.test(s);

const includesSubstringIgnoreCase = (s, substring) => {
  if (isEmptyString(s)) {
    throw Error('String cannot be empty');
  }

  if (isEmptyString(substring)) {
    throw Error('Substring cannot be empty');
  }

  return s.toLowerCase().includes(substring.toLowerCase());
};

const snakeCaseToTitleCase = (s) => _.startCase(_.toLower(s));

const lodashExtras = {
  includesSubstringIgnoreCase,
  isEmptyString,
  isBlankString,
  titleCase: snakeCaseToTitleCase,
};

export default lodashExtras;
