import {ajax} from 'rxjs/ajax';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import appConfig from '../utils/config/appConfig';

const bookingService = {
  getRemaining: () =>
    ajax({
      url: `${appConfig.emspApiDomain}/booking/remaining`,
      headers: {
        'content-type': 'application/json',
      },
    }).pipe(
      map((response) => response.response.remaining),
      catchError((error) => throwError(new Error(error.response.message)))
    ),
  createBooking: (name, email, phoneNumber, numberOfTickets, type) =>
    ajax({
      url: `${appConfig.emspApiDomain}/booking`,
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
      body: {
        name,
        phoneNumber,
        email,
        numberOfTickets,
        type,
      },
    }).pipe(
      map((response) => response.response.data),
      catchError((error) => throwError(new Error(error.response.message)))
    ),
};

export default bookingService;
